<template>
    <!-- 集团产业 -->
    <div class="Group">
        <div class="header">
            <Head :add="routeAdd"></Head>
        </div>
        <div class="main">
            <ul>
                <li>
                    <div class="filp">
                        <div class="front">
                            <div class="icons icon_1"></div>
                            <p class="cn_name">智慧城市</p>
                            <p class="en_name">WISDOM CITY</p>
                        </div>
                        <div class="back">
                            <p class="cn_name">智慧城市</p>
                            <p class="en_name">WISDOM CITY</p>
                            <div class="bar"></div>
                            <div
                                class="txt"
                            >指利用各种信息技术或创新概念，将城市的系统和服务打通、集成，以提升资源运用的效率，优化城市管理和服务，以及改善市民生活质量。</div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="filp">
                        <div class="front bg">
                            <div class="icons icon_2"></div>
                            <p class="cn_name">医疗产业</p>
                            <p class="en_name">THE MEDICAL INDUSTRY</p>
                        </div>
                        <div class="back">
                            <p class="cn_name">医疗产业</p>
                            <p class="en_name">THE MEDICAL INDUSTRY</p>
                            <div class="bar"></div>
                            <div
                                class="txt"
                            >通过打造健康档案区域医疗信息平台，利用最先进的物联网技术，实现患者与医务人员、医疗机构、医疗设备之间的互动，逐步达到信息化。</div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="filp">
                        <div class="front">
                            <div class="icons icon_3"></div>
                            <p class="cn_name">智慧养老</p>
                            <p class="en_name">WISDOM ENDOWMENT</p>
                        </div>
                        <div class="back">
                            <p class="cn_name">智慧养老</p>
                            <p class="en_name">WISDOM ENDOWMENT</p>
                            <div class="bar"></div>
                            <div class="txt">根据多年从事互联网领域研究与开发的经验积累，结合养老机构管理需求，提出了养老机构信息化系统解决方案。</div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="filp">
                        <div class="front bg">
                            <div class="icons icon_4"></div>
                            <p class="cn_name">消防产业</p>
                            <p class="en_name">FIRE CONTROL INDUSTRY</p>
                        </div>
                        <div class="back">
                            <p class="cn_name">消防产业</p>
                            <p class="en_name">FIRE CONTROL INDUSTRY</p>
                            <div class="bar"></div>
                            <div
                                class="txt"
                            >通过现代通讯网络将各建筑物内独立的火灾自动报警系统联网，在监控中心内对所有联网建筑物的火灾报警情况进行实时监测、对消防设施进行集中管理。</div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="filp">
                        <div class="front">
                            <div class="icons icon_5"></div>
                            <p class="cn_name">旅游产业</p>
                            <p class="en_name">THE TOURISM INDUSTRY</p>
                        </div>
                        <div class="back">
                            <p class="cn_name">旅游产业</p>
                            <p class="en_name">THE TOURISM INDUSTRY</p>
                            <div class="bar"></div>
                            <div class="txt">专注智慧旅游信息化服务，统一景区全域范围内的所有物联终端、应用程序数据接口，实现景区数据融通</div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="filp">
                        <div class="front bg">
                            <div class="icons icon_6"></div>
                            <p class="cn_name">教育产业</p>
                            <p class="en_name">EDUCATION INDUSTRY</p>
                        </div>
                        <div class="back">
                            <p class="cn_name">教育产业</p>
                            <p class="en_name">EDUCATION INDUSTRY</p>
                            <div class="bar"></div>
                            <div class="txt">依托物联网、云计算、无线通信等新一代信息技术所打造的物联化、智能化、感知化、泛在化的新型教育形态和教育模式。</div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Head from "../components/Head.vue";
export default {
    components: {
        Head,
    },
    data() {
        return {
            routeAdd: "",
        };
    },
    created() {
        this.routeAdd = this.$route.name;
    },
};
</script>

<style lang="scss" scoped>
.Group {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: url("../assets/images/group_background.png") no-repeat;
    .header {
        height: 6.25rem;
    }
    .main {
        position: relative;
        p {
            margin: 0px;
        }
        ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            width: 73.2rem;
            height: 37.5rem;
            position: absolute;
            top: 11.38rem;
            left: 23.44rem;
            li {
                perspective: 1000;
                -webkit-perspective: 1000;
                -moz-perspective: 1000;
                -ms-perspective: 1000;
                transform-style: preserve-3d;
                color: #1a1a1a;
                .icons {
                    width: 2.19rem;
                    height: 2.19rem;
                }
                .icon_1 {
                    background: url("../assets/fonts/city.png") no-repeat;
                }
                .icon_2 {
                    background: url("../assets/fonts/medical.png") no-repeat;
                }
                .icon_3 {
                    background: url("../assets/fonts/provide.png") no-repeat;
                }
                .icon_4 {
                    background: url("../assets/fonts/fireContral.png") no-repeat;
                }
                .icon_5 {
                    background: url("../assets/fonts/travel.png") no-repeat;
                }
                .icon_6 {
                    background: url("../assets/fonts/education.png") no-repeat;
                    background-size: 90%;
                }
                .cn_name {
                    font-size: 24px;
                    margin: 0.63rem 0 0.31rem;
                }
                .en_name {
                    font-size: 16px;
                    color: #b3b3b3;
                }
            }
        }
        li,
        .front,
        .back {
            width: 24.38rem;
            height: 18.75rem;
        }
        .flip {
            position: relative;
            transition: 0.6s;
            transform-style: preserve-3d;
        }
        .front,
        .back {
            position: absolute;
            top: 0px;
            left: 0px;
            backface-visibility: hidden;
            transition: all 0.5s ease-in-out;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .front {
            transform: rotateY(0deg);
            background-color: #fff;
            z-index: 2;
        }
        .back {
            transform: rotateY(-180deg);
            background-color: #e83a42;
            color: #fff;
            .en_name {
                color: #fff;
            }
            .bar {
                width: 3.69rem;
                height: 0.19rem;
                margin: 1.31rem 0 1.81rem;
                background-color: #fff;
            }
            .txt {
                padding: 0 2.13rem;
                font-size: 14px;
            }
        }
        li:hover .back {
            transform: rotateY(0deg);
        }
        li:hover .front {
            transform: rotateY(-180deg);
        }
        .bg {
            background-color: #f2f2f2;
        }
    }
}
</style>
